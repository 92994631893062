import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileManager from "./components/Templates/upload.js";
import VendorDetail from "./components/MainPage/Vendors_Page/VendorDetail.js";

import Vendor_Calendar from "./components/MainPage/Vendor_calendar/Vendor_Calendar.js";
const User_details = React.lazy(() =>
  import("./components/Templates/UserDetails.js")
);

// Lazy load your components
const SIgnUp = React.lazy(() => import("./components/SignUp/SIgnUp.js"));
const Password_Recovery = React.lazy(() =>
  import("./components/Password_Recovery/Password_Recovery.js")
);
const Two_Factor_Auth = React.lazy(() =>
  import("./components/Two_Factor_Auth/Two_Factor_Auth.js")
);
const New_Password = React.lazy(() =>
  import("./components/New_Password/New_Password.js")
);
const Login = React.lazy(() => import("./components/Login/Login.js"));
const Forgot_Password = React.lazy(() =>
  import("./components/Forgot_Password/Forgot_Password.js")
);
const Dashboard = React.lazy(() =>
  import("./components/MainPage/Dashboard/Dashboard.js")
);
const MainPage = React.lazy(() => import("./components/MainPage/MainPage.js"));

const Vendor = React.lazy(() =>
  import("./components/MainPage/Vendors_Page/vendor.js")
);

const Users = React.lazy(() => import("./components/MainPage/Users/Users.js"));

const Ads = React.lazy(() => import("./components/MainPage/Ads/ads.js"));

const Report = React.lazy(() =>
  import("./components/MainPage/Report_Page/report.js")
);

const Helpline = React.lazy(() =>
  import("./components/MainPage/Helpline/helpline.js")
);

const VendorStepper = React.lazy(() =>
  import("./components/MainPage/Vendor_Stepper/VendorStepper.js")
);
const Revenue = React.lazy(() =>
  import("./components/MainPage/revenue/Revenue.js")
);
const Booking = React.lazy(() =>
  import("./components/MainPage/booking/Booking.js")
);


function App() {
  return (
    <Router>
      <ToastContainer />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<Forgot_Password />} />
          <Route path="/main" element={<MainPage />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="revenue" element={<Revenue />} />
            <Route path="booking" element={<Booking />} />
            <Route path="vendor" element={<Vendor />} />
            <Route path="users" element={<Users />} />
            <Route path="ads" element={<Ads />} />
            <Route path="report" element={<Report />} />
            <Route path="helpline" element={<Helpline />} />
            <Route path="vendor-stepper" element={<VendorStepper />} />
            <Route path="vendor-detail" element={<VendorDetail />} />
            <Route path="vendor-calender" element={<Vendor_Calendar />} />

            {/* Changed here */}
          </Route>
          <Route
            path="/password-recovery/:encryptedData"
            element={<Password_Recovery />}
          />
          <Route
            path="/two-factor-auth/:encryptedData"
            element={<Two_Factor_Auth />}
          />
          <Route path="/new_password" element={<New_Password />} />
          <Route path="/sign-up" element={<SIgnUp />} />
          <Route path="/upload" element={<FileManager />} />
          <Route path="*" element={"Error"} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;

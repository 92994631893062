import React, { useState } from "react";
import axios from "axios";

const PdfManager = () => {
  const [file, setFile] = useState(null);
  const [uploadedUrl, setUploadedUrl] = useState("");
  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    // Get the signature from the server
    // const signatureResponse = await axios.get(
    //   "http://localhost:8000/api/getSignature"
    // );
    const signatureResponse = await axios.get(
      "https://nexus-api.appworkdemo.com/api/getSignature"
    );
    const { signature, timestamp } = signatureResponse.data;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadPreset", "skf3qndr"); // Your upload preset
    formData.append("signature", signature);
    formData.append("timestamp", timestamp);
    formData.append("api_key", "548871789372251");

    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/dpnxkwezq/raw/upload`, // Correct URL for raw upload
        formData
      );
      console.log("File uploaded:", response.data);
      setUploadedUrl(response.data.secure_url);
      setFiles((prevFiles) => [...prevFiles, response.data.secure_url]);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div>
      <h1>PDF Manager</h1>
      <input type="file" accept="application/pdf" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload PDF</button>
      {uploadedUrl && (
        <div>
          <h3>Uploaded PDF:</h3>
          <a href={uploadedUrl} target="_blank" rel="noopener noreferrer">
            View PDF
          </a>
        </div>
      )}
      <FileList files={files} />
    </div>
  );
};

// FileList component to display uploaded PDFs
const FileList = ({ files }) => {
  return (
    <div>
      <h3>Uploaded PDFs:</h3>
      {files.map((fileUrl, index) => (
        <div key={index}>
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            View PDF {index + 1}
          </a>
        </div>
      ))}
    </div>
  );
};

export default PdfManager;
